import Vue from 'vue'
import Vuex from 'vuex'
import { getSchnittmuster } from "@/plugins/firebase";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    schnittmuster: null
  },
  getters: {
  },
  mutations: {
    setSchnittmuster: (state, schnittmuster) => state.schnittmuster = schnittmuster,
  },
  actions: {
    async loadSchnittmuster(store) {
      const {data: schnittmuster} = await getSchnittmuster();
      store.commit("setSchnittmuster", schnittmuster);
    }
  },
  modules: {
  }
});