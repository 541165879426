<template>
  <img :src="fileLink" />
</template>
<script>
export default {
  props: ["src"],
  computed: {
    fileLink() {
      return `https://www.googleapis.com/drive/v2/files/${this.src}?alt=media&key=AIzaSyAfd8b47CCgKHJijHBcZI8HHGA89il7Tgg`
    }
  }
}
</script>