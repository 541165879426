<template>
  <div v-if="schnittmuster" class="container mx-auto p-6 grid md:grid-cols-2 gap-6">
    <schnittmuster-card v-for="s in schnittmuster" :schnittmuster="s"></schnittmuster-card>
  </div>
</template>
<script>
// @ is an alias to /src
import "@/plugins/firebase"
import GoogleDriveImage from "@/components/GoogleDriveImage";
import SchnittmusterCard from "@/components/SchnittmusterCard";

export default {
  name: 'HomeView',
  components: { SchnittmusterCard, GoogleDriveImage },
  data() {
    return {
      mediaExample: "https://drive.google.com/open?id=1uFtSCX3anYkue07TUW3NJnDqXs02wO7E".replace("https://drive.google.com/open?id=", "")
    }
  },
  computed: {
    schnittmuster() {
      return this.$store.state.schnittmuster
    }
  }
}
</script>