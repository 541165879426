<template>
  <div class="bg-white p-6 rounded-2xl shadow-xl">
    <h1>{{ schnittmuster.name }}</h1>

    <p>Hersteller: {{ schnittmuster.producer }}</p>
    <p>Zielgruppe: {{ schnittmuster.producer }}</p>
    <p>Kategorie: {{ schnittmuster.category }}</p>
    <p>Ablageort: {{ schnittmuster.storage }}</p>

    <button v-if="!imagesLoaded" @click="imagesLoaded = true" class="text-blue-600">Bilder laden</button>

    <!-- grid -->
    <div v-if="imagesLoaded" class="grid grid-cols-1 md:grid-cols-2 gap-8">
      <google-drive-image v-for="image in schnittmuster.images" :src="imageId(image)" class="rounded" />
    </div>


  </div>
</template>
<script>
import GoogleDriveImage from "@/components/GoogleDriveImage";

export default {
  components: { GoogleDriveImage },
  props: ["schnittmuster"],
  data() {
    return {
      imagesLoaded: false,
    }
  },
  methods: {
    imageId(image) {
      return image.replace("https://drive.google.com/open?id=", "");
    }
  }
}
</script>